import { AutocompleteInput, FormDataConsumer, ReferenceInput, useTranslate } from 'react-admin';
import type { ReferenceInputProps } from 'react-admin';
import get from 'lodash/get';

import { ResourceKey } from 'constants/index';

import type { ExtendedLocation } from '../../types';
import { ApmLocationOption } from './ApmLocationOption';
import { LocationCard } from './LocationCard';

export interface ApmSearchProps extends Omit<ReferenceInputProps, 'reference' | 'children' | 'source' | 'filter'> {
    sourcePrefix?: string;
}

export const ApmSearch = ({ sourcePrefix = 'location', validate, ...props }: ApmSearchProps) => {
    const translate = useTranslate();

    return (
        <>
            <ReferenceInput
                {...props}
                source={`${sourcePrefix}.id`}
                reference={ResourceKey.LOCATION}
                filter={{ type: 'apm' }}
                sort={{ field: 'id', order: 'ASC' }}
            >
                <AutocompleteInput
                    label={translate('parcel.delivery.location.label')}
                    optionText={<ApmLocationOption />}
                    inputText={ApmLocationOption.inputText}
                    matchSuggestion={ApmLocationOption.matchSuggestion}
                    fullWidth
                    validate={validate}
                />
            </ReferenceInput>

            <FormDataConsumer>
                {({ formData }) => {
                    const location = get(formData, sourcePrefix) as ExtendedLocation;

                    return location?.id ? <LocationCard locationId={location.id} /> : null;
                }}
            </FormDataConsumer>
        </>
    );
};
