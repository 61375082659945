import { Labeled, useGetMany, useTranslate } from 'react-admin';
import { Paper, Typography, Box, Stack } from '@mui/material';

import { Address } from 'modules/ui';
import { ResourceKey } from 'constants/index';

import type { ExtendedLocation } from '../../../types';

export interface LocationCardProps {
    locationId: string;
}

export const LocationCard = ({ locationId }: LocationCardProps) => {
    const translate = useTranslate();
    const { data } = useGetMany<ExtendedLocation>(ResourceKey.LOCATION, { ids: [locationId] });
    const location = data?.[0];

    if (!location) {
        return null;
    }

    return (
        <Paper elevation={1} sx={{ padding: 2, backgroundColor: 'grey.200' }}>
            <Stack spacing={2}>
                <Labeled label={translate('address.title')}>
                    <Typography variant="subtitle2">{location.name}</Typography>
                </Labeled>

                <Box display="grid" sx={{ gridTemplateColumns: 'repeat(4, auto)', gap: 2 }}>
                    <Address address={location} />

                    <Labeled label={translate('address.locationId')}>
                        <Typography variant="subtitle2">{location.id}</Typography>
                    </Labeled>
                    <Labeled label={translate('address.apmId')}>
                        <Typography variant="subtitle2">{location.apm?.id}</Typography>
                    </Labeled>
                    <Labeled label={translate('address.locationPublicIdentifier')}>
                        <Typography variant="subtitle2">{location.identifier}</Typography>
                    </Labeled>
                </Box>
            </Stack>
        </Paper>
    );
};
