import type { FC } from 'react';
import {
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    useTranslate,
    Link,
    SelectInput,
    required,
} from 'react-admin';
import { Typography, Grid, Link as MUILink } from '@mui/material';

import type { ApmCompartment } from '../../types';
import { compartmentSizes } from '../../constants';
import { ApmEditProbability } from '../ApmEditProbability';

const ApmEditCompartments: FC = () => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h6" component="h3" mt={4}>
                {translate('apm.edit.compartments')}
            </Typography>
            <ApmEditProbability mt={3} mb={1} />
            <ArrayInput source="compartments" label={''}>
                <SimpleFormIterator disableAdd disableRemove getItemLabel={() => ''} disableReordering fullWidth>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            if (!getSource || !scopedFormData) {
                                return null;
                            }
                            const { storedParcel } = scopedFormData as ApmCompartment;

                            return (
                                <Grid key={scopedFormData.id} container alignItems="center" spacing={4} width="100%">
                                    <Grid item xs={1} sm={1} md="auto">
                                        <Typography variant="subtitle1">{scopedFormData.compartmentNumber}</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md="auto" mt={2}>
                                        <SelectInput
                                            source={getSource('size')}
                                            label={translate('apm.edit.compartments.size')}
                                            choices={compartmentSizes}
                                            validate={required()}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md="auto" mt={2}>
                                        <BooleanInput
                                            source={getSource('availability')}
                                            label={translate('apm.list.availability')}
                                        />
                                    </Grid>

                                    {storedParcel && (
                                        <Grid item xs={3} sm={3} md="auto">
                                            <MUILink>
                                                <Link to={`/parcel/${storedParcel.id}/show`}>
                                                    {`${translate('apm.edit.compartments.storedParcel')} (${
                                                        storedParcel.parcelNumber
                                                    })`}
                                                </Link>
                                            </MUILink>
                                        </Grid>
                                    )}
                                </Grid>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

export default ApmEditCompartments;
