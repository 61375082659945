import { useRecordContext } from 'react-admin';

import type { ExtendedLocation } from '../../../types';

export interface ApmLocationOptionProps {}

const composeIdentifier = (record: ExtendedLocation) =>
    [record.id, record.identifier, record.name, record.postalCode].filter(Boolean).join(', ');

const inputText = (record: ExtendedLocation) => `loc_id: ${composeIdentifier(record)}`;

const matchSuggestion = (filterValue: string, suggestion?: ExtendedLocation) => {
    if (!suggestion) {
        return false;
    }

    return composeIdentifier(suggestion).toLowerCase().includes(filterValue.toLowerCase());
};

export const ApmLocationOption = (props: ApmLocationOptionProps) => {
    const record = useRecordContext<ExtendedLocation>();

    if (!record) {
        return null;
    }

    return <div>loc_id: {composeIdentifier(record)}</div>;
};

ApmLocationOption.inputText = inputText;
ApmLocationOption.matchSuggestion = matchSuggestion;
